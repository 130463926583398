import React, { useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import type {
  ActivateQuery as ActivateQueryType,
  ActivateQuery$data as ActivateQueryResponse,
} from './__generated__/ActivateQuery.graphql';
import layoutBorderStyles from '../../../../stylesheets/components/layoutBorders.module.scss';
import glimmerStyles from '../../../../stylesheets/components/glimmer.module.scss';
import { OptionsGlimmer } from '../Options';
import { ActivateEmailGlimmer } from './Email';

export const ActivateQuery = graphql`
  query ActivateQuery($key: String!) {
    loginActivation(key: $key) {
      ... on LoginActivation {
        hasExistingLogin
      }
    }
  }
`;

const Activate = () => {
  const navigate = useNavigate();

  const loaderQueryReference = useLoaderData() as PreloadedQuery<
    ActivateQueryType,
    ActivateQueryResponse
  >;
  const [queryReference] = useQueryLoader<ActivateQueryType>(
    ActivateQuery,
    loaderQueryReference
  );

  const data = usePreloadedQuery(
    ActivateQuery,
    queryReference as PreloadedQuery<ActivateQueryType, ActivateQueryType>
  );

  useEffect(() => {
    if (!data.loginActivation || data.loginActivation.hasExistingLogin) {
      navigate(window.RAILS_CONSTANTS['login_path'], {
        state: { prevPage: 'activate' },
      });
    }
  }, [data.loginActivation, navigate]);

  if (!data.loginActivation) {
    return <h2>Login activation key invalid</h2>;
  }

  return <Outlet />;
};

export const ActivateGlimmer = () => {
  const relPath = window.location.pathname.split('/').slice(-1)[0];
  return (
    <>
      <h2 className={glimmerStyles.glimmerElement}>Site name</h2>
      <h5 className={glimmerStyles.glimmerElement}>Management company name</h5>
      <hr className={layoutBorderStyles.dashed} />
      {relPath === 'email' ? <ActivateEmailGlimmer /> : <OptionsGlimmer />}
    </>
  );
};

export default Activate;
